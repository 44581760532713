<template>
    <v-container style="margin-top: 70px; margin-bottom: 70px;">
        <v-card>
            <div class="d-flex justify-center pt-3">
                <v-btn @click="backToTableData()" class="gradient-grey-button-background mx-2" fab dark small>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="$store.state.colorPrimary">
                                mdi-arrow-right
                            </v-icon>
                        </template>
                        <span>חזור לטבלה</span>
                    </v-tooltip>
                </v-btn>
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                    :style="{ color: $store.state.colorPrimary }">פרטי הרשומה</span>
            </div>
            <v-divider></v-divider>
            <v-card-text>
                <v-row class="my-5" v-if="isLoading">
                    <v-col class="d-flex justify-center">
                        <v-progress-linear indeterminate color="#FDB721" height="6"
                            style="width: 100%;"></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col v-for="(field, index) in $store.state.fields" :key="index" cols="12" md="5"
                        style="margin-left: 5%; border-bottom: 1px solid #eee;">
                        <v-row class="field-container" @mouseover="hoveredField = field.name"
                            @mouseleave="hoveredField = null">
                            <v-col cols="12" sm="6" lg="4">
                                <div>
                                    <span class="title-span">{{ field.hebrew_name }}</span>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6" lg="8"
                                class="field-value d-flex align-center justify-space-between">
                                <!-- set field view  -->
                                <div v-if="editingField !== field.name" class="field-content">
                                    <span class="text-span"
                                        v-if="field.name === 'date_created' || field.name === 'date_modified'">
                                        {{ formatDate(itemDetails[field.name]) }}</span>
                                    <span class="text-span" v-else-if="field.type !== 'לינק'">{{
                                        itemDetails[field.name] }}</span>
                                    <div v-else style="display: flex; justify-content: center;">
                                        <div v-if="itemDetails[field.name]">
                                            <v-menu v-model="isMenuOpen[index]" offset-y persistent
                                                :close-on-content-click="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn elevation="2" v-if="isAudio(itemDetails[field.name])"
                                                        icon outlined :color="$store.state.colorPrimary"
                                                        :menu-props="{ bottom: true, offsetY: true }">
                                                        <v-icon v-on="on" v-bind="attrs" dark>
                                                            mdi-play
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list v-if="isMenuOpen[index]">
                                                    <v-list-item>
                                                        <div class="menu-content pa-4">
                                                            <audio controls playsinline :src="itemDetails[field.name]">
                                                                <source :src="itemDetails[field.name]"
                                                                    type="audio/wav" />
                                                            </audio>
                                                        </div>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <a :href="itemDetails[field.name]"
                                                :download="fileNameFromUrl(itemDetails[field.name])"
                                                style="margin-inline-start: 10px;">
                                                <v-btn elevation="2" icon outlined :color="$store.state.colorPrimary">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" dark>
                                                                mdi-download
                                                            </v-icon>
                                                        </template>
                                                        <span>הורד קובץ</span>
                                                    </v-tooltip>
                                                </v-btn>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- set field edit  -->
                                <v-text-field v-else-if="field.type !== 'רשימה' && field.type !== 'רשימה מקושרת'"
                                    v-model="itemDetails[field.name]" :type="getFieldType(field)" hide-spin-buttons
                                    @blur="cancelEdit" @keydown.enter="saveEdit(key)" autofocus></v-text-field>
                                <v-autocomplete v-else-if="field.type === 'רשימה'" :items="field.list"
                                    v-model="itemDetails[field.name]" outlined dense style="margin-top: 15px;"
                                    @blur="cancelEdit" :menu-props="{ bottom: true, offsetY: true }"
                                    @keydown.enter="saveEdit(key)" autofocus></v-autocomplete>
                                <v-autocomplete v-else-if="field.type === 'רשימה מקושרת'" item-value="name"
                                    item-text="hebrew_name" @mouseover="getSourceList(field.linkedList)"
                                    :items="fieldsSourceList" v-model="itemDetails[field.name]" outlined dense
                                    style="margin-top: 15px;" :menu-props="{ bottom: true, offsetY: true }"
                                    @blur="cancelEdit" @keydown.enter="saveEdit(key)" autofocus></v-autocomplete>

                                <div v-if="editingField !== field.name && hoveredField === field.name"
                                    class="action-buttons">
                                    <v-btn icon x-small @click.stop="copyToClipboard(itemDetails[field.name])">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-if="field.name !== 'date_created' && field.name !== 'date_modified' && field.name !== 'id' && permission !== 'view'"
                                        icon x-small @click.stop="startEditing(field.name, itemDetails[field.name])">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>


</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from '@/util/utils'
import Auth from '@/services/auth.service.js'

export default {
    name: "DynamicItemDetails",
    components: {
        SnackBar,
    },
    props: {
        itemId: {
            type: String,
            required: true,
        },
        tableName: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editingField: null,
            hoveredField: null,
            tempValue: "",
            itemDetails: {},
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            fieldsSourceList: [],
            isMenuOpen: [], // Initially, the menu is closed
            permission: Auth.getUserPermission(),
            isLoading: false
        };
    },
    methods: {
        formatKey(key) {
            const field = this.$store.state.fields.find(f => f.name === key);
            return field ? field.hebrew_name : key;
        },
        getKeyDetails(key) {
            const field = this.$store.state.fields.find(f => f.name === key);
            return field ? field.type !== 'רשימה' && field.type !== 'רשימה מקושרת' : true;
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(
                () => {
                    this.showSnackBar("הועתק " + text, "green");
                },
                (err) => {
                    console.error("Failed to copy text: ", err);
                }
            );
        },
        startEditing(key, value) {
            this.editingField = key;
            this.tempValue = value;
        },
        saveEdit(key) {
            this.$set(this.itemDetails, key, this.tempValue);
            this.editingField = null;
            this.saveEditedItem();
        },
        cancelEdit() {
            this.editingField = null;
        },
        backToTableData() {
            this.$emit('backToTableData', this.tableName)
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getTItemAndTableFields() {
            this.progressShow = true;
            let token = localStorage.getItem("token");
            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/get_table_properties?tableName=" + this.tableName.name;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.$store.state.fields = jsonObject.table_fields
                    this.getItemDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved list: " + error, "red");
            }
        },
        async getItemDetails() {
            this.progressShow = true;
            let token = localStorage.getItem("token");
            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/get_item?tableName=" + this.tableName.name + "&itemId=" + this.itemId;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.isLoading = false;
                    this.showSnackBar("שגיאה בקבלת רשומה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.itemDetails = jsonObject;
                    let date = Utils.getCurrentDate();
                    this.itemDetails.date_modified = date;
                    this.isLoading = false;
                }
            } catch (error) {
                this.isLoading = false;
                this.showSnackBar("Error saved list: " + error, "red");
            }

        },
        async saveEditedItem() {

            const driversJSON = JSON.stringify({ tableName: this.tableName, item: this.itemDetails });
            this.progressShow = true;
            let token = localStorage.getItem("token");

            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/update_item";
                const res = await fetch(api, ApiServices.requestOptions("PUT", driversJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בעדכון רשומה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    let date = Utils.getCurrentDate();
                    this.itemDetails.date_modified = date;
                    this.showSnackBar("הרשומה עודכנה בהצלחה", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved list: " + error, "red");
            }

        },
        getFieldType(field) {
            if (field.type === 'מספר') {
                return 'number';
            } else if (field.type === 'טקסט' || field.type === 'לינק') {
                return 'text';
            } else if (field.type === 'תאריך') {
                return 'date';
            } else {
                return 'datetime-local';
            }
        },
        async getSourceList(linkedList) {
            try {
                this.fieldsSourceList = [];
                let token = localStorage.getItem("token");
                const tableJSON = JSON.stringify({ tableName: linkedList.tableSource, fieldName: linkedList.fieldSource });
                let api = process.env.VUE_APP_BASE_URL + "/user/get_field_data_linked_list";
                const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.fieldsSourceList = jsonObject;
                }
            } catch (error) {
                this.showSnackBar("Error get items list: " + error, "red");
            }
        },
        isAudio(link) {
            // Extract the file extension
            const parts = link.split('.');
            const extension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
            // If there's no extension, return true, can be clickcall audio
            if (extension.length > 4) {
                return true;
            }
            //it probably extension, Check if the extension matches any of the audio formats
            return ['mp3', 'ogg', 'wav'].includes(extension);
        },
        fileNameFromUrl(src) {
            // Splits the URL by '/' and then takes the last element
            return src.split('/').pop();
        },
        toggleMenu(index) {
            this.$set(this.isMenuOpen, index, !this.isMenuOpen[index]); // Toggle the menu state using Vue's $set method
        },
        formatDate(date) {
            if (!date) {
                return "";
            }
            return Utils.getPrettyDate(date);
        }
    },
    computed: {
    },
    mounted() {
        console.log(33333);
        this.isLoading = true;
        if (this.$store.state.fields.length === 0) {
            this.getTItemAndTableFields();
        } else {
            this.getItemDetails();
        }
    },
};
</script>

<style scoped>
.field-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Allows wrapping */
    gap: 8px;
    /* Adds spacing between elements */
}

.text-span {
    color: black;
    white-space: normal;
    /* Allows wrapping of long text */
    word-break: break-word;
    /* Breaks long words */
    flex-grow: 1;
    /* Makes the text span take available space */
    overflow-wrap: break-word;
    /* Ensures long text breaks properly */
}

.action-buttons {
    display: flex;
    gap: 8px;
}

.action-buttons v-btn {
    visibility: hidden;
}

.field-value:hover .action-buttons v-btn {
    visibility: visible;
}
</style>
