<template>
  <div>
    <nav-bar></nav-bar>
    <TablesList @showTable="showTable" @createTable="createTable" @showDialer="showDialer()" :newTable="newTable"
      :key="tableListKey" @isExtendedFunc="isExtendedFunc()" />

    <div id="systembody" v-if="isExtended"
      :style="{ width: $vuetify.breakpoint.mdAndUp ? '84%' : $vuetify.breakpoint.smAndUp ? '70%' : '90%', right: $vuetify.breakpoint.mdAndUp ? '16%' : $vuetify.breakpoint.smAndUp ? '30%' : '10%' }">
      <TableData v-model="tableData" v-if="tableData" :tableName="tableName" @openEditTable="openEditTable"
        @openItemPageDetails="showItem" @openTaskSchedule="openTaskSchedule" @openCrmIntigration="openCrmIntigration"
        @refreshTables="reloadTableList" @refreshData="reloadTableData" :key="tableDataKey" />
      <ItemDetails v-model="showItemDetails" v-if="showItemDetails" :tableName="tableName" :itemId="itemId"
        @backToTableData="backToTableData" />
      <EditTable v-model="editTable" v-if="editTable" :tableName="tableName" @backToTableData="backToTableData" />
      <TasksList v-model="tasksList" v-if="tasksList" :tableName="tableName" @reloadTasksList="reloadTasksList"
        :key="tasksListKey" @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
      <CrmIntigrationList v-model="crmIntigrationList" v-if="crmIntigrationList" :tableName="tableName"
        @reloadCrmIntigrationList="reloadCrmIntigrationList" :key="crmIntigrationListKey"
        @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
    </div>
    <div id="systembody" v-else
      :style="{ width: $vuetify.breakpoint.smAndUp ? '100%' : '90%', right: $vuetify.breakpoint.smAndUp ? '1%' : '10%' }">
      <!-- :style="{ width: $vuetify.breakpoint.smAndUp ? '97%' : '90%' , right: $vuetify.breakpoint.smAndUp ? '3%' : '10%'}">  -->
      <TableData v-model="tableData" v-if="tableData" :tableName="tableName" @openItemPageDetails="showItem"
        @openEditTable="openEditTable" @openTaskSchedule="openTaskSchedule" @openCrmIntigration="openCrmIntigration"
        @refreshTables="reloadTableList" @refreshData="reloadTableData" :key="tableDataKey" />
      <ItemDetails v-model="showItemDetails" v-if="showItemDetails" :tableName="tableName" :itemId="itemId"
        @backToTableData="backToTableData" />
      <EditTable v-model="editTable" v-if="editTable" :tableName="tableName" @backToTableData="backToTableData" />
      <TasksList v-model="tasksList" v-if="tasksList" :tableName="tableName" @reloadTasksList="reloadTasksList"
        :key="tasksListKey" @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
      <CrmIntigrationList v-model="crmIntigrationList" v-if="crmIntigrationList" :tableName="tableName"
        @reloadCrmIntigrationList="reloadCrmIntigrationList" :key="crmIntigrationListKey"
        @backToTableData="backToTableData" @reloadTableList="reloadTableList" />
    </div>
    <CreateDialog v-model="createDialog" v-if="createDialog" @tableCreated="tableCreated" />
    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
  </div>
</template>
<script>
import TablesList from '@/components/user/TablesList'
import TableData from '@/components/user/tableData/TableData'
import ItemDetails from '@/components/user/itemDetails/ItemComp'
import EditTable from '@/components/user/editTable/EditTable'
import TasksList from '@/components/user/taskSchedule/TasksList'
import CrmIntigrationList from '@/components/user/crmIntigration/CrmIntigrationList'
import CreateDialog from '@/components/user/editTable/dialogs/createDialog'
import Auth from '@/services/auth.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import NavBar from '@/components/widgets/navBar.vue'
import Shared from '@/util/Shared'

export default {
  name: 'home',
  components: {
    TablesList,
    TableData,
    ItemDetails,
    EditTable,
    TasksList,
    CrmIntigrationList,
    CreateDialog,
    SnackBar,
    NavBar
  },
  data: () => ({
    tableData: false,
    showItemDetails: false,
    itemId: "",
    tableName: {},
    editTable: false,
    tasksList: false,
    crmIntigrationList: false,
    createDialog: false,
    tableListKey: 0,
    tableDataKey: 0,
    tasksListKey: 0,
    crmIntigrationListKey: 0,
    dialer: false,
    newTable: null,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    isExtended: true
  }),
  methods: {
    showTable(name) {
      if (!this.$route.params.tableName || this.$route.params.tableName !== name.name) {
        this.$router.replace({ name: "table-view", params: { tableName: name.name } });
      }
      this.tableName = name;
      this.editTable = false;
      this.tasksList = false;
      this.showItemDetails = false;
      this.crmIntigrationList = false;
      // destroy previous table data (headers) before show new table
      this.reloadTableData();
      this.tableData = true;
    },
    showItem(tableName, itemId) {
      console.log(666666677777);
      console.log(tableName, itemId);
      this.tableName = tableName
      this.itemId = itemId;
      this.showItemDetails = true;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      this.tableData = false;
      if (!this.$route.params.itemID) {
        this.$router.replace({ name: "item-view", params: { tableName: tableName.name, itemID: itemId } });
      }
    },
    createTable() {
      this.tableData = false;
      this.editTable = false;
      this.tasksList = false;
      this.showItemDetails = false;
      this.crmIntigrationList = false;
      this.createDialog = true;
    },
    tableCreated(item, text, color) {
      if (item) {
        this.newTable = item;
      }
      this.showSnackBar(text, color);
    },
    openEditTable(table) {
      this.tableName = table;
      this.tableData = false;
      this.tasksList = false;
      this.showItemDetails = false;
      this.crmIntigrationList = false;
      this.editTable = true;
    },
    openTaskSchedule(table) {
      this.tableName = table;
      this.tableData = false;
      this.editTable = false;
      this.crmIntigrationList = false;
      this.showItemDetails = false;
      this.tasksList = true;
    },
    openCrmIntigration(table) {
      this.tableName = table;
      this.tableData = false;
      this.editTable = false;
      this.tasksList = false;
      this.showItemDetails = false;
      this.crmIntigrationList = true;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    reloadTableList() {
      this.tableListKey += 1;  // Increment the key to trigger a reload
    },
    reloadTableData() {
      this.tableDataKey += 1;  // Increment the key to trigger a reload
    },
    reloadTasksList() {
      this.tasksListKey += 1;  // Increment the key to trigger a reload
    },
    reloadCrmIntigrationList() {
      this.crmIntigrationListKey += 1;  // Increment the key to trigger a reload
    },
    backToTableData(tableName) {
      if (this.$route.name === "item-view") {
        this.$router.replace({ name: "table-view", params: { tableName: tableName.name } });
      }
      this.tableName = tableName;
      this.editTable = false;
      this.tasksList = false;
      this.crmIntigrationList = false;
      this.showItemDetails = false;
      this.tableData = true;
    },
    isExtendedFunc() {
      this.isExtended = !this.isExtended;
    },
    async getParamsData() {
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (this.$store.state.tables.length == 0) {
        const tables = await Shared.getTablesNames();
        if (tables === "error") {
          this.showSnackBar("שגיאה בקבלת טבלאות", "red");
        }
        this.$store.state.tables = tables
      }
      if (this.$route.params.tableName) {
        for (let i = 0; i < this.$store.state.tables.length; i++) {
          if (this.$store.state.tables[i].name == this.$route.params.tableName) {
            const table = this.$store.state.tables[i]
            if (!this.$route.params.itemID) {
              this.showTable(table);
            } else {
              const itemId = this.$route.params.itemID
              console.log(6666666);
              // show item page
              this.showItem(table, itemId);
            }
          }
        }
      }

    }
  },
  mounted() {
    document.title = 'Dashboard | CLICK-CALL'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }

    // direct access to table from url
    this.getParamsData();
  },
}
</script>
<style scoped>
div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  background: #fbfbfb;
}

::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
  background-color: rgb(219, 218, 218);
  /* Sets the thumb color to blue */
  border-radius: 3px;
  /* Optional: Adds rounded corners to the thumb */
}
</style>