<template>
  <v-container style="margin-top: 70px; margin-bottom: 90px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' lg='10'>
        <v-card>
          <v-form @submit.prevent="submitHandler" ref="form">
            <div class="d-flex justify-center pt-3">
              <v-btn @click="openMode = false" class="gradient-grey-button-background mx-2" fab dark small>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="$store.state.colorPrimary">
                      mdi-arrow-right
                    </v-icon>
                  </template>
                  <span>חזור לטבלה</span>
                </v-tooltip>
              </v-btn>
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif;"
                :style="{ color: $store.state.colorPrimary }">הגדרות פעולה</span>
            </div>
            <div class="divider"></div>
            <v-row style="padding-right: 10px; padding-left: 10px;">
              <v-col cols="12" sm="6" style="padding-bottom: 0 !important;">
                <v-text-field v-model="editedOperation.details.name" outlined label="שם הפעולה" maxlength="32"
                  style="font-weight: 500;" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" style="padding-bottom: 0 !important;">
                <v-text-field v-model="editedOperation.details.description" outlined label="תאור הפעולה"
                  style="font-weight: 500;"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" style="padding-bottom: 0 !important;">
                <v-select :items="requestMethod" v-model="editedOperation.action.method" outlined label="שיטת שליחה"
                  style="font-weight: 500;"></v-select>
              </v-col>
              <v-col cols="12" sm="6" style="padding-bottom: 0 !important; padding-top: 8px !important;">
                <div
                  style="border: 1px solid rgb(145, 139, 139); height: 60px; margin-bottom: 10px; border-radius: 5px;">
                  <span style="font-size: small; margin: 3px;">תנאי הפעלה</span>
                  <div style="direction: ltr;" class="d-flex align-items-center">
                    <span
                      style="font-weight: 600; font-size: large; margin-inline-start: 10px; margin-inline-end: 5px;">if(</span>
                    <v-text-field v-model="editedOperation.action.condition[0]" hide-details dense label="value 1"
                      style="font-weight: 500; direction: ltr; max-width: 220px;"></v-text-field>
                    <v-select :items="operatorsList" v-model="editedOperation.action.condition[1]" hide-details dense
                      label=""
                      style="font-weight: bold; font-size: large; max-width: 70px; margin-inline-start: 10px; margin-inline-end: 10px; direction: ltr;"></v-select>
                    <v-text-field v-model="editedOperation.action.condition[2]" hide-details dense label="value 2"
                      style="font-weight: 500; direction: ltr; max-width: 210px;"></v-text-field>
                    <span
                      style="font-weight: 600; font-size: large; margin-inline-start: 10px; margin-inline-end: 5px;">)</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="3" style="padding-bottom: 0 !important;"
                v-if="editedOperation.details.triger === 'when_row_updated'">
                <v-select v-model="editedOperation.action.trigerField" :items="fields" item-text="hebrew_name"
                  item-value="name" outlined label="שדה להפעלה" :rules="[v => !!v || 'שדה חובה']" required
                  style="font-weight: 500;"></v-select>
              </v-col>
              <v-col :cols="editedOperation.details.triger === 'when_row_updated' ? 9 : 12"
                style="padding-bottom: 0 !important;">
                <v-text-field v-model="editedOperation.action.url" outlined label="URL"
                  :rules="[v => !!v || 'שדה חובה']" required style="font-weight: 500; direction: ltr;"></v-text-field>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0 !important;">
                <v-textarea v-model="editedOperation.action.headers" outlined label="headers" rows="3"
                  style="font-weight: 500; direction: ltr;"></v-textarea>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0 !important;">
                <v-textarea v-model="editedOperation.action.body" :rules="[jsonRule]" outlined label="body" rows="5"
                  style="font-weight: 500; direction: ltr;"></v-textarea>
              </v-col>

              <v-col cols="12" style="padding-bottom: 0 !important; margin-inline-start: 20px; display: flex; align-items: center;">
                <v-icon v-if="socketEnabled" v-on="on" :color="$store.state.colorPrimary" medium class="mr-2 me-3"
                  @click="socketDataDialog = true">
                  mdi-eye-outline
                </v-icon>
                <v-switch :color="$store.state.colorPrimary" hide-details v-model="socketEnabled" label="הפעל בדיקה">
                </v-switch>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0 !important; margin-inline-start: 20px;">
                <v-switch :color="$store.state.colorPrimary" hide-details v-model="editedOperation.action.send_reports"
                  label="שלח שגיאה למייל">
                </v-switch>
              </v-col>
              <v-col cols="12" style="padding-bottom: 0 !important; margin-inline-start: 20px;">
                <v-switch :color="$store.state.colorPrimary" v-model="editedOperation.action.mode" label="מופעל/מושבת">
                </v-switch>
              </v-col>
              <v-col cols="12" class="d-flex" style="margin: 20px;">
                <v-btn @click="saveChanges()" style="margin-left: 20px;" class="gradient-yellow-button-background">שמור
                  שינויים</v-btn>
                <v-btn @click="deleteOperation()" style="margin-left: 20px;"
                  class="gradient-yellow-button-background">מחק
                  פעולה</v-btn>
              </v-col>
              <v-col cols="12" v-if="progressShow" class="d-flex justify-center">
                <v-progress-circular indeterminate :color="$store.state.colorPrimary"></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    <SocketDataDialog v-model="socketDataDialog" v-if="socketDataDialog" :data="socketData"></SocketDataDialog>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service.js'
import SnackBar from '@/components/widgets/snackBar.vue'
import SocketDataDialog from '@/components/user/crmIntigration/SocketResponseDialog.vue'
import Auth from '@/services/auth.service.js'
import { io } from 'socket.io-client';

export default {
  components: {
    SnackBar,
    SocketDataDialog
  },
  props: {
    operationToEdit: Object,
    tableName: Object,
    trigers: Array,
    value: { type: Boolean, default: false },
  },
  data: () => ({
    fields: [],
    tables: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedOperation: {},
    requestMethod: ["GET", "POST", "PUT", "DELETE"],
    operatorsList: ["===", "!==", "<", ">", "<=", ">="],
    socket: null,
    socketEnabled: false,
    socketData: [],
    socketDataDialog: false
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
    jsonRule() {
      return (value) => {
        if (this.editedOperation.action.method !== 'GET') {
          try {
            if (!this.editedOperation.action.headers || !this.editedOperation.action.headers.includes("application/json")) {
              return true
            }
            JSON.parse(value);
            return true; // Valid JSON
          } catch (e) {
            return 'The body must be valid JSON.'; // Invalid JSON
          }
        }
        return true; // If method is GET, pass validation regardless of JSON validity
      };
    }
  },
  methods: {
    async saveChanges() {
      if (this.$refs.form.validate()) {
        try {
          if (this.editedOperation.action.mode === true) {
            this.editedOperation.action.mode = "on"
          } else {
            this.editedOperation.action.mode = "off"
          }
          if (this.editedOperation.action.send_reports === true) {
            this.editedOperation.action.send_reports = "yes"
          } else {
            this.editedOperation.action.send_reports = "no"
          }
          let token = localStorage.getItem("token");
          const tableJSON = JSON.stringify(this.editedOperation);

          let api = process.env.VUE_APP_BASE_URL + "/user/update_crm_operation";
          const res = await fetch(api, ApiServices.requestOptions("PUT", tableJSON, token));
          const jsonObject = await res.json();
          if (res.status >= 400) {
            this.showSnackBar("שגיאה בעדכון הפעולה" + jsonObject.message, "red");
          } else if (res.status === 200) {
            this.$emit("operationUpdated", this.editedOperation, "הפעולה עודכנה בהצלחה!", "green");
          }
        } catch (error) {
          this.showSnackBar("Error update operation: " + error, "red");
        }
      }
    },
    async deleteOperation() {
      try {
        let token = localStorage.getItem("token");
        const tableJSON = JSON.stringify(this.editedOperation);

        let api = process.env.VUE_APP_BASE_URL + "/user/delete_crm_operation";
        const res = await fetch(api, ApiServices.requestOptions("DELETE", tableJSON, token));
        const jsonObject = await res.json();
        if (res.status >= 400) {
          this.showSnackBar("שגיאה במחיקת הפעולה" + jsonObject.message, "red");
        } else if (res.status === 200) {
          this.$emit("operationUpdated", this.editedOperation, "הפעולה נמחקה בהצלחה!", "green");
        }
      } catch (error) {
        this.showSnackBar("Error update operation: " + error, "red");
      }
    },
    // Start the socket connection with parameters
    startSocket() {
      if (this.socket) {
        console.log('Socket already connected');
        return;
      }
      this.socketDataDialog = true;
      this.socket = io(process.env.VUE_APP_BASE_URL); // Replace with your server URL

      this.socket.emit('joinRoom', {
        token: localStorage.getItem("token"),
        tableName: this.tableName.name,
        taskId: this.operationToEdit.details.id,
      });

      // Listen for data from the server
      this.socket.on('recieveMessage', (data) => {
        console.log('Data received from server:', data);
        this.socketData.push(data); // Update the data property
      });

      this.socket.on('connect', () => {
        console.log('Socket connected');
      });

      this.socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      this.socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    },

    // Close the socket connection
    closeSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
        this.socketDataDialog = false;
        console.log('Socket connection closed');
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  watch: {
    socketEnabled(newValue) {
      console.log(newValue);
      if (newValue) {
        this.startSocket();
      } else {
        this.closeSocket();
      }
    }
  },
  beforeDestroy() {
    // Ensure socket is closed when the component is destroyed
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  mounted() {

    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
    // deep clone
    this.editedOperation = JSON.parse(JSON.stringify(this.operationToEdit));
    if (this.editedOperation.action.mode === "on" || this.editedOperation.action.mode === true) {
      this.editedOperation.action.mode = true
    } else {
      this.editedOperation.action.mode = false
    }
    if (this.editedOperation.action.send_reports === "yes" || this.editedOperation.action.send_reports === true) {
      this.editedOperation.action.send_reports = true
    } else {
      this.editedOperation.action.send_reports = false
    }
    this.fields = this.$store.state.fields;
    this.fields.unshift({ hebrew_name: "הכל", name: "הכל" });
    // this.tables = this.$store.state.tables;
  },

}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.divider-col {
  border-left: 1px solid #ccc !important;
  /* Adjust the color and thickness as needed */
  padding-left: 10px;
  /* Add padding to create space between the border and content */
  margin-left: 10px;
  /* Adjust the margin to align the divider with the cards above */
}
</style>