<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card class="gradient-grey-button-background">
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <!-- <v-row v-if="formTitle === 'ערוך רשומה'">
                        <v-col cols="12" sm="6" v-for="(key, index) in Object.keys(filteredKeys)" :key="index">
                            <v-text-field v-model="itemEdited[key]" :label="key"></v-text-field>
                        </v-col>
                    </v-row> -->
                    <!-- <v-row v-else> -->
                    <v-row>
                        <v-col cols="12" sm="6" v-for="(field, index) in filteredFields" :key="index">
                            <v-text-field v-if="field.type !== 'רשימה' && field.type !== 'רשימה מקושרת'" v-model="itemEdited[field.value]"
                                :label="field.text" :type="getFieldType(field)" hide-spin-buttons></v-text-field>
                            <v-autocomplete v-else-if="field.type === 'רשימה'" :items="field.list"
                                v-model="itemEdited[field.value]" :label="field.text" outlined dense
                                style="margin-top: 15px;" :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>
                            <v-autocomplete v-else-if="field.type === 'רשימה מקושרת'" item-value="name" item-text="hebrew_name" @mouseover="getSourceList(field.linkedList)"  :items="fieldsSourceList"
                                v-model="itemEdited[field.value]" :label="field.text" outlined dense
                                style="margin-top: 15px;" :menu-props="{ bottom: true, offsetY: true }"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.colorPrimary" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn :color="$store.state.colorPrimary" text
                    @click="formTitle === 'ערוך רשומה' ? saveEditedItem() : saveNewItem()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import utils from '@/util/utils'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        itemToEdit: Object,
        fields: Array,
        formTitle: String,
        tableName: Object,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {},
        fieldsSourceList:[]
    }),

    methods: {
        async saveNewItem() {

            const itemJSON = JSON.stringify({ tableName: this.tableName, item: this.itemEdited });
            console.log("newItem");
            this.progressShow = true;
            let token = localStorage.getItem("token");

            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/add_item";
                const res = await fetch(api, ApiServices.requestOptions("POST", itemJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.$emit("itemAdded", "", "שגיאה בהוספת רשומה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.itemEdited.id = jsonObject.id
                    let date = utils.getCurrentDate();
                    this.itemEdited.date_created = date
                    this.$emit("itemAdded", this.itemEdited, "הרשומה נוספה בהצלחה", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved item: " + error, "red");
                this.$emit("itemAdded", "", "Error saved item: " + error, "red");
            }

        },
        async saveEditedItem() {

            const driversJSON = JSON.stringify({ tableName: this.tableName, item: this.itemEdited });
            this.progressShow = true;
            let token = localStorage.getItem("token");

            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/update_item";
                const res = await fetch(api, ApiServices.requestOptions("PUT", driversJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.$emit("itemEdited", "", "שגיאה בעדכון רשומה: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    let date = utils.getCurrentDate();
                    this.itemEdited.date_modified = date;
                    this.$emit("itemEdited", this.itemEdited, "הרשומה עודכנה בהצלחה", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error saved list: " + error, "red");
                this.$emit("itemEdited", "", "Error edit driver: " + error, "red");
            }

        },
        getFieldType(field) {
            if (field.type === 'מספר') {
                return 'number';
            } else if (field.type === 'טקסט' || field.type === 'לינק') {
                return 'text';
            } else if (field.type === 'תאריך') {
                return 'date';
            } else {
                return 'datetime-local';
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getSourceList(linkedList) {

            try {
                this.fieldsSourceList = [];
                let token = localStorage.getItem("token");
                const tableJSON = JSON.stringify({ tableName: linkedList.tableSource, fieldName: linkedList.fieldSource});

                let api = process.env.VUE_APP_BASE_URL + "/user/get_field_data_linked_list";
                const res = await fetch(api, ApiServices.requestOptions("POST", tableJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar(" שגיאה בקבלת נתונים" + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.fieldsSourceList = jsonObject;
                    console.log(this.fieldsSourceList);
                }
            } catch (error) {
                this.showSnackBar("Error get items list: " + error, "red");
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        filteredKeys() {
            const { id, date_created, date_modified, ...rest } = this.itemToEdit;
            return rest;
        },
        filteredFields() {
            return this.fields.filter((field) => field.value !== "date_created" && field.value !== "date_modified" && field.value !== "id" && field.show !== false);
        },
    },
    mounted() {
        if (this.formTitle === 'ערוך רשומה') {
            // clone object to edit to new obj
            // this.itemEdited = { ...this.itemToEdit };
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        } else {
            // insert the keys to itemEdited object
            this.fields.forEach((field) => {
                this.$set(this.itemEdited, field.value, '');
            });
        }

    },
}
</script>
<style></style>
  