<template>
  <v-main>
    <v-app-bar rounded color="gradient-yellow-button-background" height="55" fixed>
      <v-spacer></v-spacer>
      <v-toolbar-title>
      </v-toolbar-title>

      <v-toolbar-items class="hidden-sm-and-down ms-3">
        <v-btn class="i" height="100%" text v-for="item in filteredMenu" :to="item.link" :key="item.id" v-if="item.show"
          style="color: #0d2c6d; font-size: 1.2rem; font-weight: bold;"><v-icon left>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
        <!-- link to external website if exist -->
        <v-btn class="i" height="100%" text v-if="menu[4] && menu[4].show" :href="dynamicLink(menu[4])"
          style="color: #0d2c6d; font-size: 1.2rem; font-weight: bold;"><v-icon left>{{ menu[4].icon }}</v-icon>
          {{ menu[4].title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <div class="d-flex justify-end pt-1 pl-3">
        <div class="user-info" style="display: flex; justify-content: center; align-items: center;">
          <span class="mr-2" style="font-weight: 500; font-size: 13px; color: #0d2c6d;">{{ userName }}</span>
          <span class="mr-2" style="font-size: 13px; color: #0d2c6d;" @click="showLogs()">{{ email }}</span>
        </div>

        <div class="hidden-sm-and-down">
          <div class="logout-button-container" @mouseover="isFocused = true" @mouseleave="isFocused = false"
            @click="logOut()">
            <v-img src="../../assets/log-out.svg"
              :style="{ width: isFocused ? '32px' : '24px', height: isFocused ? '32px' : '24px', marginRight: '10px', marginTop: '5px' }"
              class="image-transition" />
          </div>
        </div>

        <v-tooltip bottom class="logout-button-container" v-if="isAgent">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="#0d2c6d" dark @click="swichBackToAgent()" style="margin-right: 15px;">
              mdi-swap-horizontal
            </v-icon>
          </template>
          <span>חזור לניהול</span>
        </v-tooltip>
        <v-tooltip bottom class="logout-button-container" v-else-if="isAdmin">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="#0d2c6d" dark @click="swichBackToAdmin()" style="margin-right: 15px;">
              mdi-swap-horizontal
            </v-icon>
          </template>
          <span>חזור לניהול</span>
        </v-tooltip>

      </div>

      <div class="hidden-md-and-up">

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon :color="$store.state.colorPrimary"></v-app-bar-nav-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list>
              <v-list-item v-for="item in menu" :key="item.id" v-if="item.show" v-bind="getItemAttributes(item)">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list-item :key="'logout'" @click="logOut()">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>

    <template>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>
  </v-main> 
</template>

<script>
import Auth from '@/services/auth.service.js'

export default {
  data() {
    return {
      menu: [],
      userMenu: [
        { id: "database", title: "נתונים", link: "/user", show: true, icon: "mdi-database-outline" },
        { id: "guides", title: "מדריכים", link: "/user_guides", show: true, icon: "mdi-book-open-variant-outline" },
        { id: "contact_us", title: "צור קשר", link: "/user_contact_us", show: true, icon: "mdi-email-outline" },
        { id: "user_account", title: "אזור אישי", link: "/user_account", show: true, icon: "mdi-account-outline" },
        { id: "user_special_page", title: "אפשרויות נוספות", link: "/", show: false, icon: "mdi-dots-horizontal" },
      ],
      adminMenu: [
        { id: "admin_users_list", title: "ניהול משתמשים", link: "/manage_users", icon: "mdi-account-outline", show: true },
      ],
      userName: "",
      email: "",
      role: "",
      isFocused: false,
      isAdmin: false,
      isAgent: false,
      asSpecialPage: false,
      specialPage: "",
      overlay: false
    }

  },
  methods: {

    logOut() {
      localStorage.removeItem("token");
      this.$router.replace({ name: "login" })
    },
    getItemAttributes(item) {
      if (item.id === 'user_special_page') {
        // When the item's id is 'user_special_page', return an object with the 'to' attribute
        return { href: this.dynamicLink() };
      } else {
        // Otherwise, return an object with the 'href' attribute
        return { to: item.link };
      }
    },
    setNavListIItems() {
      this.userName = Auth.getUserName();
      this.email = Auth.getUserEmail();
      this.role = Auth.getUserRole();
      if (this.role === "admin" || this.role === "superAdmin" || this.role === "agent") {
        this.menu = this.adminMenu;
      } else {
        this.menu = this.userMenu;
      }
      if (localStorage.getItem("tokenAdmin")) {
        this.isAdmin = true;
      }
      if (localStorage.getItem("tokenAgent")) {
        this.isAgent = true;
      }
      this.specialPage = Auth.getUserSpecialPage();
      ;
      if (!this.specialPage) {
        return false
      }
      this.asSpecialPage = true
    },
    swichBackToAdmin() {
      this.overlay = true;
      const currentRole = Auth.getUserRole();
      localStorage.setItem("token", localStorage.getItem("tokenAdmin"));
      localStorage.removeItem("tokenAdmin");
      this.isAdmin = false;
      if (localStorage.getItem("tokenAgent")) {
        localStorage.removeItem("tokenAgent");
      }
      if (currentRole === "user") {
        this.$router.replace({ name: "manageUsers" });
      } else {
        window.location.reload();
      }
      this.overlay = false;
    },
    swichBackToAgent() {
      this.overlay = true;
      localStorage.setItem("token", localStorage.getItem("tokenAgent"));
      localStorage.removeItem("tokenAgent");
      this.isAgent = false;
      this.$router.replace({ name: "manageUsers" });
      this.overlay = false;
    },
    dynamicLink() {
      // Generate the dynamic URL with the parameter
      let token = localStorage.getItem("token");
      return this.specialPage + "?token=" + token;
    },
    showLogs() {
      if (this.role === "admin" || this.role === "superAdmin") {
        this.$router.push({ name: 'logs' })
      }
    }
  },
  watch: {
    asSpecialPage(newVal) {
      // Find the user_special_page item in the userMenu array
      const specialPageItem = this.userMenu.find((item) => item.id === "user_special_page");

      // Conditionally update the show property based on newVal (asSpecialPage)
      if (specialPageItem) {
        specialPageItem.show = newVal;
      }
    },
  },
  computed: {
    filteredMenu() {
      // If the menu array length is greater than 4, filter it to show only the first 4 items
      if (this.menu.length > 4) {
        return this.menu.slice(0, 4);
      } else {
        return this.menu;
      }
    },
  },
  mounted() {
    this.setNavListIItems();
  },
}
</script>
<style>
.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}

.header-cell-red {
  color: rgb(202, 4, 4) !important;
  font-weight: 700;
}

.header-cell-white {
  color: #fff
}

@font-face {
  font-family: 'Heebo';
  src: url('@/fonts/Heebo.ttf') format('truetype');
}

@font-face {
  font-family: 'Heebo';
  src: url('@/fonts/Heebo-Bold.ttf') format('truetype');
  font-weight: bold;
}

.i {
  font-family: 'Heebo', sans-serif;
}

.image-transition {
  transition: width 0.3s ease, height 0.3s ease;
  /* Adjust the animation duration and easing as needed */
}
</style>