<template>
    <div>
      <nav-bar></nav-bar>
      <UserFullDetails />
    </div>
  </template>
    <script>
  import UserFullDetails from '@/components/admin/UserFullDetails'
    import Auth from '../../services/auth.service'
    import NavBar from '@/components/widgets/navBar.vue'

    export default {
      components: {
        UserFullDetails,
        NavBar
      },
      mounted() {
        let auth = Auth.checkAuth();
        let role = Auth.getUserRole();
        if (!auth || (role !== "admin" && role !== "superAdmin" && role !== "agent")) {
          this.$router.replace({ name: "login" })
        }
      },
    }
    </script>
      