<template>
    <v-dialog v-model="openMode" persistent max-width="500px">
        <v-card style="padding: 20px;">
            <div class="d-flex justify-center ">
                <span> {{ importedSuccess.length }} רשומות נוספו בהצלחה</span>
            </div>
            <div class="d-flex justify-center">
                <span>{{ importedFailed.length }} רשומות נכשלו</span>
            </div>
            <div class="d-flex justify-center">
                <div v-if="progressSave" style="margin-right: 10px;">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.colorPrimary" style="color: #2892fc;" text @click="refreshPage">בטל
                    ייבוא</v-btn>
                <v-btn :color="$store.state.colorPrimary" text @click="actionConfirmed">שמור נתונים מאקסל</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import axios from 'axios'

export default {
    props: {
        tableName: Object,
        importedSuccess: Array,
        importedFailed: Array,
        importFailedTextList: Array,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        progressSave: false,
    }),

    methods: {
        actionConfirmed() {
            this.saveImported();
        },
        refreshPage() {
            location.reload();
        },
        async saveImported() {
            const filteredCodesJSON = JSON.stringify({ tableName: this.tableName, items: this.importedSuccess });
            this.progressSave = true;
            let token = localStorage.getItem("token");


            // try {
            //     let api = process.env.VUE_APP_BASE_URL + "/user/add_items_imported";
            //     const res = await fetch(api, ApiServices.requestOptions("POST", filteredCodesJSON, token));
            //     this.progressSave = false;
            //     if (res.status === 400) {
            //         this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
            //     } else if (res.status === 200) {
            //         this.openMode = false;
            //         this.$emit("codesImportedSuccess", "הנתונים נשמרו בהצלחה!", "green");
            //     }else{
            //         this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red"); 
            //     }
            // } catch (error) {
            //     this.progressSave = false;
            //     this.$emit("codesImportedSuccess", "Error saved list: " + error, "red");
            // }
            let api = `${process.env.VUE_APP_BASE_URL}/user/add_items_imported`;

            // Prepare the headers and Axios request configuration
            const config = {
                method: 'post', // HTTP method
                url: api, // API endpoint
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, // Authorization header
                },
                data: filteredCodesJSON, // Data to be sent in the request body
                timeout: 15000, // Timeout set to 5000 milliseconds (5 seconds)
            };

            try {
                // Making the Axios request with the configured options
                const res = await axios(config);

                this.progressSave = false; // Assuming this is meant to control a loading indicator

                // Handling the response based on the status code
                if (res.status === 400) {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                } else if (res.status === 200) {
                    this.openMode = false; // Assuming this is meant to control modal/dialog visibility
                    this.$emit("codesImportedSuccess", "הנתונים נשמרו בהצלחה!", "green");
                } else {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                }
            } catch (error) {
                this.progressSave = false; // Handling the error case by stopping the loading indicator
                if (error.response && error.response.status === 400) {
                    this.$emit("codesImportedSuccess", "שגיאה בשמירת נתונים", "red");
                } else if (error.code === 'ECONNABORTED') {
                    this.$emit("codesImportedSuccess", "Request timeout", "red");
                } else {
                    this.$emit("codesImportedSuccess", "Error saved list: " + error.message, "red");
                }
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.importFailedTextList.length > 0) {
            alert(this.importFailedTextList.map((text, index) => `${index + 1}. ${text}`).join("\n"));
        }
    }
}
</script>
  
    