<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card class="gradient-grey-button-background" style="max-height: 400px; overflow-y: auto;">
            <v-card-title class="text-h5 text-start" dir="ltr">{{ "API Request Details" }}</v-card-title>
            <v-card-text>
                <!-- Loop through the data object -->
                <div v-if="data && Object.keys(data).length" dir="ltr" class="text-start">
                    <v-list dense>
                        <!-- Iterate over the top-level keys (e.g., request, response) -->
                        <template v-for="(section, sectionName) in data">
                            <v-list-item style="min-height: 10px !important;">
                                <v-list-item-title class="wrap-text" style="white-space: normal; word-break: break-word; min-height:10px !important;">
                                    <h5>{{ sectionName }}</h5>
                                </v-list-item-title>
                            </v-list-item>

                            <!-- Iterate over the fields in each section -->
                            <template v-for="(value, key) in section" >
                                <!-- Display key-value pairs -->
                                <v-list-item v-if="typeof value !== 'object'" style="padding: 4px 0;">
                                    <v-list-item-title class="wrap-text" style="white-space: normal; word-break: break-word; margin: 0;">
                                        <strong>{{ key }}:</strong> {{ value }}
                                    </v-list-item-title>
                                </v-list-item>
                                <!-- Recursively display nested objects -->
                                <template v-else>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <h5>{{ key }}:</h5>
                                            <!-- <strong>{{ key }}:</strong> -->
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-for="(nestedValue, nestedKey) in value" :key="nestedKey" style="min-height: 25px !important;">
                                        <v-list-item-title class="wrap-text" style="white-space: normal; word-break: break-word; margin-left: 10px !important; min-height:25px !important;">
                                            <strong>{{ nestedKey }}:</strong> {{ nestedValue }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </template>
                            <v-divider></v-divider>
                        </template>
                    </v-list>
                </div>
                <div v-else dir="ltr" class="text-start">
                    <p>No data to display</p>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeCreate" class="text-start" dir="ltr">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>



<script>
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        value: { type: Boolean, default: false },
        data: { type: Array },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        tableName: "",
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
    }),

    methods: {
        closeCreate() {
            this.openMode = false;
        }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 10px;
    /* Adjust the width of the scrollbar */
}

/* Customizes the scrollbar track (space to scroll) */
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    /* Sets the track color to white */
}

/* Customizes the scrollbar thumb (the draggable element) */
::-webkit-scrollbar-thumb {
    background-color: rgb(219, 218, 218);
    /* Sets the thumb color to blue */
    border-radius: 3px;
    /* Optional: Adds rounded corners to the thumb */
}
</style>