import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import UserView from '../views/user/UserView.vue'
import UserAccountView from '../views/user/AccountView.vue'
import UserGuidesView from '../views/user/GuidesView.vue'
import UserContactView from '../views/user/ContactUsView.vue'
import AdminView from '../views/admin/AdminView.vue'
import DetailsView from '../views/admin/DetailsView.vue'
import RessetPassView from '../views/ResetPasswordView.vue'
import LogsView from '../views/admin/LogsView.vue'
import DirectTableView from '../views/user/UserView.vue'
import DirectItemView from '../views/user/UserView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
  },
  {
    path: '/table/:tableName',
    name: 'table-view',
    component: DirectTableView
  },
  {
    path: '/table/:tableName/:itemID',
    name: 'item-view',
    component: DirectItemView
  },
  {
    path: '/user_guides',
    name: 'user_guides',
    component: UserGuidesView,
  },
  {
    path: '/user_contact_us',
    name: 'user_contact_us',
    component: UserContactView,
  },
  {
    path: '/user_account',
    name: 'user_account',
    component: UserAccountView,
  },
  {
    path: '/manage_users',
    name: 'manageUsers',
    component: AdminView,
  },
  {
    path: '/user_details',
    name: 'userDetails',
    component: DetailsView,
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
