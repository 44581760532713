import ApiServices from '@/services/api.service.js'

class Shared {
    constructor() {
    }
  
    static async getTablesNames() {
        let api = process.env.VUE_APP_BASE_URL + "/user/get_tables_list"
        let token = localStorage.getItem("token");

        try {
            const response = await fetch(api, ApiServices.requestOptions("POST", "", token));
            if (response.status >= 400) {
               return "error";
            } else if (response.status === 200) {
                const jsonObject = await response.json();
                return jsonObject;
            }
        } catch (error) {
            console.log(error);
            return "error";
        }
    }
  
 
  }
  
  export default Shared;
  
  