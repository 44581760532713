
class Services {
  constructor() {
  }

  static getCurrentDate(date) {

    let currentDate; 
    currentDate = new Date();
    if (date) {
      currentDate = new Date(date);
    }
    let formattedDate;
    let formattedTime;
    formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;
    formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(currentDate.getMinutes()).padStart(2, "0")}:${String(currentDate.getSeconds()).padStart(2, "0")}`;

    return formattedDate + "T" + formattedTime
  }

  static getPrettyDate(date) {

    let currentDate = new Date(date);
    
    let formattedDate;
    let formattedTime;
    formattedDate = `${String(currentDate.getDate()).padStart(2, "0")}-${String(currentDate.getMonth() + 1).padStart(2, "0")}-${currentDate.getFullYear()}`;
    formattedTime = `${String(currentDate.getHours()).padStart(2, "0")}:${String(currentDate.getMinutes()).padStart(2, "0")}:${String(currentDate.getSeconds()).padStart(2, "0")}.${String(currentDate.getMilliseconds()).padStart(3, "0")}`;

    return formattedTime + " " + formattedDate
  }
}

export default Services;

